import { Paper } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar/AppBar";
import Tab from "@material-ui/core/Tab/Tab";
import Tabs from "@material-ui/core/Tabs/Tabs";
import { withStyles } from "@material-ui/core/styles/";
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { consultationActions } from '../actions/consultationAction';
import CompletedConsultations from '../components/ConsultationQueueTask/CompletedConsultations';
import SideMenuBar from '../components/Home/sideMenuBar/SideMenuBar';
import TopBar from '../components/Home/topBar/TopBar';
import LoginPage from './LoginPage';

import orange from '@material-ui/core/colors/orange';

const styles = theme => ({
    palette: {
        primary: orange,
    },
    indicator: {
        backgroundColor: "orange"
    },
    default_tabStyle: {
        color: 'grey',
    },
    active_tabStyle: {
        fontWeight: 'bold',
        color: 'black',
    }
});
class TodaysConsultationsPage extends React.Component {
    state = {
        activeTabIndex: 0, // change to 0
    }

    handleChange = (event, value) => {
        this.setState({ activeTabIndex: value });
    };

    componentDidMount() {
        const { setFilterDateFrom, setFilterDateTo, consultationDetails } = this.props;
        if (!(consultationDetails.completed?.to && consultationDetails.completed?.from)) {
            setFilterDateFrom(moment().startOf('day').valueOf());
            setFilterDateTo(moment().endOf('day').valueOf());
        }
        if (window.location.hash === '#completed') {
            this.setState({
                activeTabIndex: 2,
            });
        }
    }

    renderAppBar = () => {
        const { classes } = this.props;
        const { activeTabIndex } = this.state;
        return (
            <div style={{ position: 'relative', width: '92vw' }} >
                <Paper elevation={0} >
                    <div >
                        <AppBar position="static" elevation={0} style={{ marginTop: "2vh", width: '100%', background: '#f4f4f4' }}>
                            <Tabs value={activeTabIndex} onChange={this.handleChange} style={{ textTransform: "capitalize", fontWeight: 'bold' }} classes={{ indicator: classes.indicator }} >
                                {/* <Tab
                                    className=
                                    {this.state.activeTabIndex === 2 ? classes.active_tab : classes.default_tabStyle}
                                    style={{ color: 'black', textTransform: "capitalize", display: 'none' }}
                                    label="Pending Consultations" />
                                <Tab
                                    className=
                                    {this.state.activeTabIndex === 1 ? classes.active_tab : classes.default_tabStyle}
                                    style={{ color: 'black', textTransform: "capitalize", display: 'none' }}
                                    label="Upcoming Consultations" /> */}
                                <Tab
                                    className=
                                    {this.state.activeTabIndex === 0 ? classes.active_tab : classes.default_tabStyle}
                                    style={{ color: 'black', textTransform: "capitalize" }}
                                    label="Completed Consultations" />
                            </Tabs>
                        </AppBar>
                    </div>
                </Paper>
            </div>
        )
    }

    renderConsultationTabs = () => {
        // if (this.state.activeTabIndex === 2) {
        //     return <PendingConsultations />;
        // }
        // if (this.state.activeTabIndex === 1) {
        //     return <UpcomingConsultations />;
        // }
        if (this.state.activeTabIndex === 0) {
            return <CompletedConsultations />;
        }
        return null;
    }

    render() {
        if (!this.props.details.isLoggedIn) {
            return (
                <LoginPage />
            )
            // } else if ((this.props.error && this.props.error.findCustomerByPhoneNumberError === errors.FORBIDDEN)) {
            //     this.props.logOut();
            //     return (
            //         <LoginPage />
            //     )
        } else {
            return (
                // <div className='main' style={{
                //     height: "98vh",
                //     // width: '5%',
                //     display: 'flex',
                //     // backgroundColor: '#f4f4f4'
                //     backgroundColor: 'white',
                // }}>
                //     <SideMenuBar />
                //     <div>

                //    </div>
                // </div>
                <div className='main' style={{
                    height: "100vh",
                    minWidth: '80vw',
                    // marginLeft: '10vh',
                    // display: 'flex',
                    backgroundColor: '#f4f4f4',

                }}>
                    <TopBar />
                    <div style={{ display: 'flex' }}>
                        <SideMenuBar />
                        <div style={{ marginLeft: '1.8vh', marginTop: '-1vh' }}>
                            {this.renderAppBar()}
                            <div style={{ height: 'calc(80vh - 4vh)', padding: '2vh', width: 'calc(95vw - 4vh - 1.5vh)', background: '#FFFFFF00' }}>
                                {this.renderConsultationTabs()}
                            </div>
                        </div>
                    </div>
                </div>

            );
        }
    }
}

const mapStateToProps = (state) => {
    return { details: state.auth, error: state.error, consultationDetails: state.consultationDetails };
};

const mapDispatchToProps = {
    setFilterDateFrom: consultationActions.setFilterDateFrom,
    setFilterDateTo: consultationActions.setFilterDateTo,
}

const styleWrapper = withStyles(styles);
const reduxWrapper = connect(mapStateToProps, mapDispatchToProps);

export default reduxWrapper(styleWrapper(TodaysConsultationsPage));
